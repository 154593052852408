import React, { FC } from 'react'
import styles from './InsightsHeader.module.scss'
import { classNames } from 'utils/css'
import { Text } from 'components/Text'
import { Box, BoxProps } from 'components/Box'
import { UnstyledButton, UnstyledButtonProps } from 'components/UnstyledPrimitives'
import { Icon } from '@juullabs/react-components'

export interface InsightsHeaderProps extends BoxProps {
  /** are the section contents hidden/collapsed */
  isHidden?: boolean
  /** heading level, which corresponds to title size  */
  level?: 'h2' | 'h3'
  /** title of the section */
  title: string
  /** show/hide button params */
  toggleButton?: UnstyledButtonProps
}

export const InsightsHeader: FC<InsightsHeaderProps> = ({
  className,
  isHidden,
  level = 'h2',
  title,
  toggleButton,
  ...rest
}) => {
  const toggleText = isHidden ? 'Show' : 'Hide'

  return (
    <Box
      className={classNames(styles.InsightsHeader, className)}
      tagName='header'
      {...rest}
    >
      <Text
        tagName={level}
        typography={level === 'h2' ? 'subtitleBase' : 'subtitleSmall'}
        color='graphica|base|100'
        message={{ content: title }}
      />
      {toggleButton?.onClick && (
        <UnstyledButton
          aria-label={toggleText}
          className={styles.toggleInsightButton}
          {...toggleButton}
        >
          <span>{toggleText}</span>
          <Icon
            color={'graphica|base|100'}
            name={isHidden ? 'icon/16x16/expand' : 'icon/16x16/collapse'}
          />
        </UnstyledButton>
      )}
    </Box>
  )
}
