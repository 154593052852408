/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** WebApp Carousel Events */
export namespace WebAppCarousel {

  /** Carousel titles for WebApp */
  export enum CarouselName {
    MyDevices = "MyDevices",
    Juul2Facts = "JUUL2Facts",
    Warranty = "Warranty",
  }

  /** Screen which the carousel appears for WebApp */
  export enum ScreenName {
    Home = "Home",
  }

  /** Fires when a user selects a specific carousel slide */
  type CarouselSlideChangeEventProps = {
    activeIndex: Number
    carouselName: CarouselName
    previousIndex: Number
    screenName: ScreenName
    slideName?: string
    totalSlides: Number
  }

  export const CarouselSlideChangeEvent = ({
    activeIndex,
    carouselName,
    previousIndex,
    screenName,
    slideName,
    totalSlides,
  }: CarouselSlideChangeEventProps): Event => {
    const properties: Record<string, any> = {
      active_index: activeIndex,
      carousel_name: carouselName,
      previous_index: previousIndex,
      screen_name: screenName,
      slide_name: slideName,
      total_slides: totalSlides,
    }

    return new Event({
      properties: properties,
      eventName: 'carousel_slide_change',
      type: EventType.Stat,
    })
  }
}
