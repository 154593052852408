/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** WebApp Session Events */
export namespace WebAppSession {

  /** Occurs when a user loses their session and is logged out. */
  export const SignoutOnSessionClearedEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'signout_on_session_cleared',
      type: EventType.Stat,
    })
  }

  /** Occurs when too many attempts have been made to login, likely due to a bad connection. */
  type OauthSessionFailedEventProps = {
    attempts: Number
  }

  export const OauthSessionFailedEvent = ({
    attempts,
  }: OauthSessionFailedEventProps): Event => {
    const properties: Record<string, any> = {
      attempts: attempts,
    }

    return new Event({
      properties: properties,
      eventName: 'oauth_session_failed',
      type: EventType.Stat,
    })
  }
}
