import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'
import { LongTermTrend, TrendDirection } from '../../config'
import { classNamesFsExclude } from 'utils/css'

const MICROCHART_SIZE = 56

export interface PuffsPerDayMicrochartProps extends BoxProps {
  setIsValid(isValid: boolean): void
  setTrend(trend: LongTermTrend): void
}

export const PuffsPerDayMicrochart: FC<PuffsPerDayMicrochartProps> = ({
  setIsValid,
  setTrend,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachLongTermTrendMicro(
      currentCanvas,
      (direction: TrendDirection, weeksTrending: number, isValid: boolean) => {
        setTrend({ direction, weeksTrending })
        setIsValid(isValid)
      },
    )

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        className={classNamesFsExclude()}
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${MICROCHART_SIZE}px`,
          width: `${MICROCHART_SIZE}px`,
        }}
      />
    </Box>
  )
}
