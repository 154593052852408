/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** All Cards in WebApp */
export namespace WebAppCards {

  /** Card names for WebApp */
  export enum CardName {
    DeviceCard = "DeviceCard",
    NewDeviceCard = "NewDeviceCard",
    MostRecentOrderCard = "MostRecentOrderCard",
    SubscriptionCard = "SubscriptionCard",
    ClaimsSurveyCard = "ClaimsSurveyCard",
    ClaimsCard = "ClaimsCard",
    WarrantySurveyCard = "WarrantySurveyCard",
    WarrantyCard = "WarrantyCard",
  }

  /** General card states. */
  export enum CardState {
    Error = "Error",
    Loading = "Loading",
  }

  /** DeviceCard states */
  export enum DeviceCardState {
    Connected = "Connected",
    Disconnected = "Disconnected",
    DisconnectedWithAlert = "DisconnectedWithAlert",
    Reconnecting = "Reconnecting",
    Unauthenticated = "Unauthenticated",
  }

  /** ClaimsCard states */
  export enum ClaimsCardState {
    NoSmoke = "NoSmoke",
    NoCarbonMonoxide = "NoCarbonMonoxide",
    NoTar = "NoTar",
  }

  /** SurveyCard states */
  export enum SurveyCardState {
    WouldLikeMoreClaimsContent = "WouldLikeMoreClaimsContent",
    WasClaimsContentHelpful = "WasClaimsContentHelpful",
    WasAutoWarrantyHelpful = "WasAutoWarrantyHelpful",
    ThankYou = "ThankYou",
  }

  /** Screen which the card appears for WebApp */
  export enum ScreenName {
    Home = "Home",
    Settings = "Settings",
  }

  type ClaimsCardEventProps = {
    cardState: ClaimsCardState
    screenName: ScreenName
  }

  export const ClaimsCardEvent = ({
    cardState,
    screenName,
  }: ClaimsCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'ClaimsCard',
      card_state: cardState,
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }

  type ClaimsSurveyCardEventProps = {
    cardState: SurveyCardState
    screenName: ScreenName
  }

  export const ClaimsSurveyCardEvent = ({
    cardState,
    screenName,
  }: ClaimsSurveyCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'ClaimsSurveyCard',
      card_state: cardState,
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }

  type DeviceCardEventProps = {
    cardState: DeviceCardState
    screenName: ScreenName
  }

  export const DeviceCardEvent = ({
    cardState,
    screenName,
  }: DeviceCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'DeviceCard',
      card_state: cardState,
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }

  type NewDeviceCardEventProps = {
    screenName: ScreenName
  }

  export const NewDeviceCardEvent = ({
    screenName,
  }: NewDeviceCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'NewDeviceCard',
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }

  type MostRecentOrderCardEventProps = {
    cardState: CardState
    screenName: ScreenName
  }

  export const MostRecentOrderCardEvent = ({
    cardState,
    screenName,
  }: MostRecentOrderCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'MostRecentOrderCard',
      card_state: cardState,
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }

  type SubscriptionCardEventProps = {
    cardState: CardState
    screenName: ScreenName
  }

  export const SubscriptionCardEvent = ({
    cardState,
    screenName,
  }: SubscriptionCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'SubscriptionCard',
      card_state: cardState,
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }

  type WarrantyCardEventProps = {
    screenName: ScreenName
  }

  export const WarrantyCardEvent = ({
    screenName,
  }: WarrantyCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'WarrantyCard',
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }

  type WarrantySurveyCardEventProps = {
    cardState: SurveyCardState
    screenName: ScreenName
  }

  export const WarrantySurveyCardEvent = ({
    cardState,
    screenName,
  }: WarrantySurveyCardEventProps): Event => {
    const properties: Record<string, any> = {
      card_name: 'WarrantySurveyCard',
      card_state: cardState,
      screen_name: screenName,
    }

    return new Event({
      properties: properties,
      eventName: 'card_loaded',
      type: EventType.Stat,
    })
  }
}
