import React, { FC, useEffect, useRef } from 'react'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { Box, BoxProps } from 'components/Box'
import { LongTermTrend, TrendDirection } from '../../config'
import { classNamesFsExclude } from 'utils/css'

export interface PuffsPerDayLinechartProps extends BoxProps {
  height: number
  setIsValid(isValid: boolean): void
  setTrend(trend: LongTermTrend): void
}

export const PuffsPerDayLinechart: FC<PuffsPerDayLinechartProps> = ({
  height,
  setIsValid,
  setTrend,
  ...rest
}) => {
  const { usageInsights } = useDeviceConnection()
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!usageInsights) return

    const currentCanvas = canvasRef.current

    usageInsights?.attachLongTermTrend(currentCanvas, (
      direction: TrendDirection,
      totalWeeks: number,
      weeksTrending: number,
      isValid: boolean,
    ) => {
      setTrend({ direction, totalWeeks, weeksTrending })
      setIsValid(isValid)
    })

    return () => {
      if (currentCanvas != null) {
        usageInsights?.detachAdapter(currentCanvas)
      }
    }
  }, [usageInsights])

  return (
    <Box tagName='figure' {...rest}>
      <canvas
        className={classNamesFsExclude()}
        ref={canvasRef}
        style={{
          display: 'block',
          height: `${height}px`,
          width: '100%',
        }}
      />
    </Box>
  )
}
