import React, { FC, useState, useEffect } from 'react'
import { Reoverlay } from 'reoverlay'
import { addWeeks, isAfter } from 'date-fns'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { useLocalStorage } from 'hooks'
import { CLOUD_SYNC, CloudSync } from 'types/cloud-sync'
import { CloseableNotice } from '../../components'
import { EnableCloudSyncErrorModal } from 'modules/Settings/modals'
import { BoxProps } from 'components/Box'
import { keepInsightsNoticeData } from './localization'
import { PrivacyPolicyLink } from 'components/Links'
import { WebAppScreens } from 'analytics-events'

export const KeepYourInsightsNotice: FC<BoxProps> = (boxProps) => {
  // data
  const { button, description, heading } = keepInsightsNoticeData

  // hooks
  const {
    isLogSyncEnabled,
    isLogSyncStatusKnown,
    toggleCloudSyncOptIn,
    isLogSyncToggleUpdating,
  } = useDeviceConnection()

  // local storage
  const [cloudSyncCache, setCloudSyncCache]
    = useLocalStorage<CloudSync>(CLOUD_SYNC, {})

  // util
  const handleOpenState = () => (isLogSyncStatusKnown
    && !isLogSyncEnabled
    && (!cloudSyncCache?.usageInsightsResurfaceRefDate
      || isAfter(
        new Date(),
        addWeeks(cloudSyncCache.usageInsightsResurfaceRefDate, 1),
      )
    )
  )

  // local state
  const [isOpen, setIsOpen] = useState<boolean>(handleOpenState())

  // effects
  useEffect(() => {
    setIsOpen(handleOpenState())
  }, [isLogSyncStatusKnown])

  // handlers
  const handleEnableCloudSync = async() => {
    try {
      await toggleCloudSyncOptIn(true)
      setIsOpen(false)
    } catch (e) {
      Reoverlay.showModal('TextModal', EnableCloudSyncErrorModal)
    }
  }

  const handlePostponeCloudSync = () => {
    setCloudSyncCache({
      ...cloudSyncCache,
      usageInsightsResurfaceRefDate: new Date(),
    })
    setIsOpen(false)
  }

  return (
    <CloseableNotice
      button={{
        ...button,
        clickEvent: WebAppScreens.UsageInsights.ClickTurnOnAutomaticBackup_KeepInsightsNotice(),
        loading: isLogSyncToggleUpdating,
        onClick: handleEnableCloudSync,
      }}
      closeButton={{
        clickEvent: WebAppScreens.UsageInsights.ClickClose_KeepInsightsNotice(),
        onClick: handlePostponeCloudSync,
      }}
      description={{
        ...description,
        values: {
          privacyPolicyLink: (
            <PrivacyPolicyLink
              clickEvent={WebAppScreens.UsageInsights.ClickPrivacyPolicy_KeepInsightsNotice()}
              key='privacyPolicyLink'
            />
          ),
        },
      }}
      heading={heading}
      id='keep-your-insights-notice'
      open={isOpen}
      {...boxProps}
    />
  )
}
