import React, { FC, HTMLAttributes, ReactNode, ComponentProps } from 'react'
import { classNames, variationName } from 'utils/css'
import styles from './Notification.module.scss'
import { Icon } from '@juullabs/react-components'
import { UnstyledButton, UnstyledLink, UnstyledLinkProps } from 'components/UnstyledPrimitives'
import { Spinner } from 'components/Spinner'
import { Event } from 'analytics-events'

export type NotificationType = 'approval' | 'highlight' | 'neutral' | 'warning'

export interface NotificationProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  /** Text of the button */
  buttonText?: string
  /** additional classes */
  className?: string
  /** Tracking for button click event */
  clickEvent?: Event
  /** text content of the notification */
  content?: string | ReactNode
  /** Text used for notification title */
  heading?: string
  /** determines Icon used, should be the right color */
  icon?: ComponentProps<typeof Icon>
  /** target URL for the CTA */
  link?: Pick<UnstyledLinkProps, 'href' | 'local' | 'openInNewTab'>
  /** Action for NotificationButton */
  onClick?: (...args) => void
  /** includes spinner */
  spinner?: boolean
  /** determines the background color scheme */
  type?: NotificationType
}

// temp until we use atom library
export const NotificationIcons:Record<string, ComponentProps<typeof Icon>> = {
  AlertFilledRed: { color: 'red|dark|100', name: 'icon/24x24/alert_filled' },
  AlertOutlinedBlack: { color: 'steel|base|100', name: 'icon/24x24/alert_outlined' },
  CheckBlack: { color: 'steel|base|100', name: 'icon/24x24/check' },
  LockedFilledBlack: { color: 'steel|base|100', name: 'icon/24x24/standard_lock-locked-filled' },
  UnlockedFilledGreen: { color: 'green|dark|100', name: 'icon/24x24/standard_lock-unlocked-filled' },
}

export const Notification: FC<NotificationProps> = ({
  buttonText,
  className,
  clickEvent,
  content,
  heading,
  icon,
  link,
  onClick,
  spinner,
  type = 'neutral',
  ...rest
}) => (
  <div
    className={classNames(
      styles.Notification,
      type && styles[variationName('color', type)],
      className,
    )}
    {...rest}
  >
    {spinner && (
      <div className={styles.spinnerWrap}>
        <Spinner color='dark' size='base' />
      </div>
    )}
    {icon && (
      <div className={styles.iconWrap}>
        <Icon className={styles.icon} {...icon} />
      </div>
    )}
    <div className={styles.body}>
      {heading && <div className={styles.heading}>{heading}</div>}
      {content && <div className={styles.content}>{content}</div>}
      {buttonText && (
        link?.href ? (
          <UnstyledLink
            aria-label={buttonText}
            className={styles.button}
            clickEvent={clickEvent}
            onClick={onClick}
            {...link}
          >
            {buttonText}
          </UnstyledLink>
        ) : (
          <UnstyledButton
            aria-label={buttonText}
            className={styles.button}
            clickEvent={clickEvent}
            onClick={onClick}
            type='button'
          >
            {buttonText}
          </UnstyledButton>
        )
      )}
    </div>
  </div>
)
