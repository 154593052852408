import { TextFormats } from 'components/Text'
import { classNamesFsExclude } from 'utils/css'

export const title: TextFormats = [
  { className: classNamesFsExclude(), content: '%{totalWeeks}', tagName: 'span' },
  { content: ' Weeks View' },
]

export const heading: TextFormats = [
  { content: 'You have vaped ' },
  { className: classNamesFsExclude(), content: '%{trend}', tagName: 'span' },
  { content: ' usual for the past ' },
  { className: classNamesFsExclude(), content: '%{weeksTrending}', tagName: 'span' },
  { content: ' weeks.' },
]

export const legend: TextFormats = { content: 'We use an industry standard algorithm to determine which trendline or trendlines to display. If a change in behavior is observed, this algorithm may select and display a pair of multi-week trendlines that best identify the change.' }
