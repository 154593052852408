import Chai from 'types/chai'
import Kotlin from 'types/kotlin'
import { bugsnagClient } from 'utils/bugsnag'
import {
  getAppVersion,
  getDataDogBuildType,
  getIsDebugMode,
  getIsPreProdEnvironment,
} from 'utils/app'
import { firebaseConfig } from './firebase'
import { RemoteConfigOverlay } from 'modules/RemoteConfigManager/reference'

export const USER_CLIENT_ID_KEY = 'conx-user-client-id'

export const deviceCloudProxy = process.env.NEXT_PUBLIC_DEVICE_CLOUD_PROXY_ENV
  ? new Chai.DeviceCloudProxy(
    process.env.NEXT_PUBLIC_DEVICE_CLOUD_PROXY_ENV,
    process.env.NEXT_PUBLIC_DEVICE_CLOUD_PROXY_URL || null,
  )
  : undefined

/**
 * Builds a remote config overlay map
 *
 * @param buildVersion - The build version of the app, see NEXT_PUBLIC_BUILD_VERSION
 * @returns - A map of remote config keys and values or undefined
 *
 * For an up to date list of keys and value types,
 * reference the Remote Config Manager in the dev tools dropdown.
 */
export const getRemoteConfigOverlay = (
  buildVersion: string,
): Kotlin.collections.KtMap<keyof RemoteConfigOverlay, any> | undefined => {
  switch (buildVersion) {
  case 'eut11':
    return Kotlin.collections.KtMap.fromJsMap(
      new Map<keyof RemoteConfigOverlay, any>([
        ['datadog_enabled', true],
        ['logcat_to_datadog_logging_level', 'verbose'],
        ['pod_pull_pairing_enabled', true],
        ['show_harm_reduction_claims', false],
        ['study_mode_enabled', true],
        ['track_peripheral_puff_events', true],
        ['usage_sync', false],
        ['warranty', false],
      ]),
    )
  default:
    return undefined
  }
}

export const environmentOverrides = (): Chai.Overrides => {
  const overrides = new Chai.Overrides()
  overrides.fakeMode = getIsDebugMode()
  overrides.unlinkExistingUser = process.env.NEXT_PUBLIC_CHAI_UNLINK_EXISTING_USER === 'true'
  overrides.mobileCloudHost = process.env.NEXT_PUBLIC_MOBILECLOUD_URL
  overrides.runtimeIoAccount = process.env.NEXT_PUBLIC_RUNTIME_IO_ACCOUNT
  overrides.logLevel = process.env.NEXT_PUBLIC_CHAI_LOG_LEVEL
  overrides.encryptedUsageSync = process.env.NEXT_PUBLIC_ENCRYPTION_ENABLED !== 'false'
  overrides.deviceCloudProxy = deviceCloudProxy
  overrides.mutableRemoteConfig = getIsPreProdEnvironment()
  overrides.remoteConfigOverlay = getRemoteConfigOverlay(process.env.NEXT_PUBLIC_BUILD_VERSION)
  return overrides
}

export const ChaiConfig = {
  appName: 'conx-web',
  appVersion: getAppVersion(),
  buildType: getDataDogBuildType(),
  cloudApiCompatibilityVersion: BigInt(1),
  overrides: environmentOverrides(),
}

export const newChaiContext = (uid: string, config: typeof ChaiConfig) => {
  const contextConfig = new Chai.ContextConfig()
  contextConfig.clientId = uid
  contextConfig.appName = config.appName
  contextConfig.appVersion = config.appVersion
  contextConfig.appVersionBuildCode = config.cloudApiCompatibilityVersion
  contextConfig.localization = null // TODO localization
  contextConfig.overrides = config.overrides
  contextConfig.buildType = config.buildType
  contextConfig.firebaseConfig = firebaseConfig
  return contextConfig
}

// Hook up the SDK exception logging system to forward to BugSnag.
export const installChaiCrashlytics = () => {
  if (Chai.installCrashlytics === undefined) {
    return
  }
  const bugsnagBridge: Chai.CrashlyticsBridge = {
    logException: (error) => {
      bugsnagClient?.notify?.({
        message: `${error.name}: ${error.message}`,
        name: 'conxsdk_crashlytics_exception',
      })
    },
    logMessage: (message: string) => {
      bugsnagClient?.leaveBreadcrumb?.(message)
    },
    setCustomKey: (_key: string, _value: string) => {
      // not needed
    },
    setUserId: (_userId: string) => {
      // not needed
    },
  }
  Chai.installCrashlytics(bugsnagBridge)
}
