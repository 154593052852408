import React, { FC, useState, useMemo } from 'react'
import styles from './ActivityUpdates.module.scss'
import { classNames } from 'utils/css'
import {
  InsightsSection,
  InsightsSectionProps,
  ActivityUpdatePreview,
} from '../../components'
import { shortTrendPreview, longTrendPreview, title } from './localization'
import { Box } from 'components/Box'
import Routes from 'types/routes'
import { TrendDirection, LongTermTrend, trendWording } from '../../config'
import { PuffsPerDayMicrochart } from '../../charts/PuffsPerDayMicrochart'
import { WeeklyPuffsMicrochart } from '../../charts/WeeklyPuffsMicrochart'
import { useLocalStorage } from 'hooks'
import { LONG_TERM_TREND_SEEN, SHORT_TERM_TREND_SEEN } from '../../config'
import { WebAppScreens, WebAppInsights, Event } from 'analytics-events'

export interface ActivityUpdatesProps
  extends Omit<InsightsSectionProps, 'title'> {
    loading?: boolean
    showLongTermTrend: boolean
    showShortTermTrend: boolean
  }

export const ActivityUpdates: FC<ActivityUpdatesProps> = ({
  loading,
  showLongTermTrend,
  showShortTermTrend,
  ...rest
}) => {
  // short term trend chart state
  const [isShortTrendValid, setIsShortTrendValid] = useState<boolean>(null)
  const [shortTrend, setShortTrend] = useState<TrendDirection>(null)

  // long term trend chart state
  const [isLongTrendValid, setIsLongTrendValid] = useState<boolean>(null)
  const [{ direction: longTrend, weeksTrending: longTrendWeeks }, setLongTrend]
    = useState<LongTermTrend>({ direction: null, weeksTrending: null })

  // shimmer local storage state
  const [hasSeenShortTermTrend, setHasSeenShortTermTrend]
    = useLocalStorage<boolean>(SHORT_TERM_TREND_SEEN)
  const [hasSeenLongTermTrend, setHasSeenLongTermTrend]
    = useLocalStorage<boolean>(LONG_TERM_TREND_SEEN)

  /* XNOR boolean to determine if we see activity updates in two columns. */
  const allowTwoColumns = showLongTermTrend == showShortTermTrend

  // events
  const shortTrendEvent = useMemo<Event>(() => {
    if (isShortTrendValid === null) return null
    return WebAppInsights.ShortTermTrendPreviewEvent({ isValid: isShortTrendValid })
  }, [isShortTrendValid])

  const longTrendEvent = useMemo<Event>(() => {
    if (isLongTrendValid === null) return null
    return WebAppInsights.LongTermTrendPreviewEvent({ isValid: isLongTrendValid })
  }, [isLongTrendValid])

  return (
    <InsightsSection
      className={styles.ActivityUpdates}
      title={title}
      {...rest}
    >
      <Box
        className={classNames(
          styles.activityUpdateLinks,
          allowTwoColumns && styles.twoColumns,
        )}
      >
        {showShortTermTrend && (
          <ActivityUpdatePreview
            accessibilityLabel={shortTrendPreview.accessibilityLabel}
            body={{
              message: shortTrendPreview.body,
              values: { trend: trendWording[shortTrend] },
            }}
            chart={(
              <WeeklyPuffsMicrochart
                setTrend={setShortTrend}
                setIsValid={setIsShortTrendValid}
              />
            )}
            className={styles.previewCard}
            clickEvent={WebAppScreens.UsageInsights.ClickShortTermTrendLink()}
            heading={{ message: shortTrendPreview.heading }}
            href={Routes.ShortTermTrends}
            isValid={isShortTrendValid}
            loading={loading || isShortTrendValid === null}
            shimmer={!hasSeenShortTermTrend}
            previewEvent={shortTrendEvent}
            onClick={() => setHasSeenShortTermTrend(true)}
          />
        )}
        {showLongTermTrend && (
          <ActivityUpdatePreview
            accessibilityLabel={longTrendPreview.accessibilityLabel}
            body={{
              message: longTrendPreview.body,
              values: { trend: trendWording[longTrend] },
            }}
            chart={(
              <PuffsPerDayMicrochart
                setTrend={setLongTrend}
                setIsValid={setIsLongTrendValid}
              />
            )}
            className={styles.previewCard}
            clickEvent={WebAppScreens.UsageInsights.ClickLongTermTrendLink()}
            heading={{
              message: longTrendPreview.heading,
              values: { weeks: longTrendWeeks },
            }}
            href={Routes.LongTermTrends}
            isValid={isLongTrendValid}
            loading={loading || isLongTrendValid === null}
            shimmer={!hasSeenLongTermTrend}
            previewEvent={longTrendEvent}
            onClick={() => setHasSeenLongTermTrend(true)}
          />
        )}
      </Box>
    </InsightsSection>
  )
}
