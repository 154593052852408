import { TextModalProps } from 'modules/ReoverlayModal/components/TextModal'
import { WebAppModal } from 'analytics-events'

// Settings Testing Modals

export const ClearBrowserHistoryModal: TextModalProps = {
  description: [
    { content: 'As part of this test, we want to show you what happens when you clear your browser history.' },
    { content: 'This button simulates what deleting your browser history would do to the data stored in the JUUL App. Your browser history outside of this app won’t be affected.' },
    { content: 'Please only click on this button when asked to do so.' },
  ],
  heading: { content: 'Please wait to run this simulation until asked to do so.' },
  modalCloseEvent: WebAppModal.ClearBrowserHistoryModal.ClickClose(),
  modalScreenEvent: WebAppModal.ClearBrowserHistoryModalEvent(),
  primaryButton: {
    clickEvent: WebAppModal.ClearBrowserHistoryModal.ClickSimulateBrowserClearingEvent(),
    content: 'Simulate Browser Clearing Event',
  },
}

// Deprecated but storing for reference.
export const ClearDeviceDataModal: TextModalProps = {
  description: [
    { content: 'As part of this test, we want to show you what happens when you clear the data from your JUUL2.' },
    { content: 'This button simulates how clearing the data stored on your JUUL2 would behave in the JUUL App. Your data would still exist on the cloud if you opt-in to automatic backups.' },
    { content: 'Please only click on this button when asked to do so.' },
  ],
  heading: { content: 'Please wait to run this simulation until asked to do so.' },
  modalCloseEvent: WebAppModal.ClearDeviceDataModal.ClickClose(),
  modalScreenEvent: WebAppModal.ClearDeviceDataModalEvent(),
  primaryButton: {
    clickEvent: WebAppModal.ClearDeviceDataModal.ClickSimulateClearingDataFromJuul2(),
    content: 'Simulate Clearing Data From JUUL2',
  },
}

// Device Testing Modals

export const SwapFirmwareImageModal: TextModalProps = {
  description: [
    { content: 'Swap to the alternate/previous firmware image on a JUUL device.' },
    { content: 'This process includes a reboot of the JUUL device which will cause it to disconnect momentarily.' },
  ],
  heading: { content: 'Swap firmware image' },
  modalCloseEvent: WebAppModal.SwapFirmwareImageModal.ClickClose(),
  modalScreenEvent: WebAppModal.SwapFirmwareImageModalEvent(),
  primaryButton: {
    clickEvent: WebAppModal.SwapFirmwareImageModal.ClickProceed(),
    content: 'Proceed',
    primary: 'red',
  },
  secondaryButton: {
    clickEvent: WebAppModal.SwapFirmwareImageModal.ClickDone(),
    content: 'Done',
  },
  stackControls: true,
}

// Settings Cloud Sync Modals

export const DisableCloudSyncModal: TextModalProps = {
  description: [
    { content: 'Your puff data will be removed from your account and will no longer be synced across your devices. Your data will remain on this device until the browser’s cache is cleared.' },
  ],
  heading: { content: 'Turning off automatic backups' },
  icon: { name: 'icon/48x48/service_outage' },
  modalCloseEvent: WebAppModal.DisableCloudSyncModal.ClickClose(),
  modalScreenEvent: WebAppModal.DisableCloudSyncModalEvent(),
  primaryButton: {
    clickEvent: WebAppModal.DisableCloudSyncModal.ClickTurnOffAutomaticBackups(),
    content: 'Turn Off Automatic Backups',
    primary: 'red',
  },
  secondaryButton: {
    clickEvent: WebAppModal.DisableCloudSyncModal.ClickNeverMind(),
    content: 'Never Mind',
  },
  stackControls: true,
}

export const EnableCloudSyncErrorModal: TextModalProps = {
  align: 'center',
  description: [
    { content: 'There was a problem syncing with the cloud. Please check your internet connection and try again.' },
  ],
  heading: { content: 'Syncing error' },
  icon: { name: 'icon/48x48/alert_outlined' },
  modalCloseEvent: WebAppModal.EnableCloudSyncErrorModal.ClickClose(),
  modalScreenEvent: WebAppModal.EnableCloudSyncErrorModalEvent(),
}

export const DisableCloudSyncErrorModal: TextModalProps = {
  align: 'center',
  description: [
    { content: 'Please check your internet connection and try again. If the problem persists, please try again after signing out and signing back in.' },
  ],
  heading: { content: 'Error disabling automatic backups' },
  icon: { name: 'icon/48x48/alert_outlined' },
  modalCloseEvent: WebAppModal.DisableCloudSyncErrorModal.ClickClose(),
  modalScreenEvent: WebAppModal.DisableCloudSyncErrorModalEvent(),
}
