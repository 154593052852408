/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** Usage Insight and Activity Update WebApp States */
export namespace WebAppInsights {

  /** Pod History Donut Chart State */
  export enum PodHistoryDonutChartState {
    HasUnknownFlavor = "has_unknown_flavor",
    Valid = "valid",
    ZeroPods = "zero_pods",
  }

  /** Puffs So Far Line Chart State */
  export enum PuffsSoFarLineChartState {
    HasAverageLine = "has_average_line",
    NoAverageLine = "no_average_line",
  }

  /** Puff Average by Day Tree Map State */
  export enum AveragePuffDaysTreeMapState {
    SameHighestLowest = "same_highest_lowest",
    Valid = "valid",
  }

  /** Puff Average by Hour Bar Chart State */
  export enum AveragePuffHoursBarChartState {
    SameHighestLowest = "same_highest_lowest",
    Valid = "valid",
  }

  /** DeviceCard Info Box State */
  export enum PuffsLeftState {
    LowLevel = "low_level",
    WithEstimate = "with_estimate",
    WithoutEstimate = "without_estimate",
  }

  type AveragePuffDaysEventProps = {
    chartState: AveragePuffDaysTreeMapState
    isValid: boolean
  }

  export const AveragePuffDaysEvent = ({
    chartState,
    isValid,
  }: AveragePuffDaysEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'AveragePuffDays',
      chart_state: chartState,
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type AveragePuffHoursEventProps = {
    chartState: AveragePuffHoursBarChartState
    isValid: boolean
  }

  export const AveragePuffHoursEvent = ({
    chartState,
    isValid,
  }: AveragePuffHoursEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'AveragePuffHours',
      chart_state: chartState,
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type LongTermTrendExpandedEventProps = {
    isValid: boolean
  }

  export const LongTermTrendExpandedEvent = ({
    isValid,
  }: LongTermTrendExpandedEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'LongTermTrendExpanded',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type LongTermTrendPreviewEventProps = {
    isValid: boolean
  }

  export const LongTermTrendPreviewEvent = ({
    isValid,
  }: LongTermTrendPreviewEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'LongTermTrendPreview',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PodHistoryEventProps = {
    chartState: PodHistoryDonutChartState
    isValid: boolean
  }

  export const PodHistoryEvent = ({
    chartState,
    isValid,
  }: PodHistoryEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PodHistory',
      chart_state: chartState,
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PodsThisWeekEventProps = {
    chartState: PodHistoryDonutChartState
    isValid: boolean
  }

  export const PodsThisWeekEvent = ({
    chartState,
    isValid,
  }: PodsThisWeekEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PodsThisWeek',
      chart_state: chartState,
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffHistoryEventProps = {
    isValid: boolean
  }

  export const PuffHistoryEvent = ({
    isValid,
  }: PuffHistoryEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffHistory',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffsOnChargeEventProps = {
    chartState: PuffsLeftState
    isValid: boolean
  }

  export const PuffsOnChargeEvent = ({
    chartState,
    isValid,
  }: PuffsOnChargeEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffsOnCharge',
      chart_state: chartState,
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffsOnPodEventProps = {
    chartState: PuffsLeftState
    isValid: boolean
  }

  export const PuffsOnPodEvent = ({
    chartState,
    isValid,
  }: PuffsOnPodEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffsOnPod',
      chart_state: chartState,
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffsSoFarEventProps = {
    chartState: PuffsSoFarLineChartState
    isValid: boolean
  }

  export const PuffsSoFarEvent = ({
    chartState,
    isValid,
  }: PuffsSoFarEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffsSoFar',
      chart_state: chartState,
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type PuffsThisWeekEventProps = {
    isValid: boolean
  }

  export const PuffsThisWeekEvent = ({
    isValid,
  }: PuffsThisWeekEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'PuffsThisWeek',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type ShortTermTrendExpandedEventProps = {
    isValid: boolean
  }

  export const ShortTermTrendExpandedEvent = ({
    isValid,
  }: ShortTermTrendExpandedEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'ShortTermTrendExpanded',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }

  type ShortTermTrendPreviewEventProps = {
    isValid: boolean
  }

  export const ShortTermTrendPreviewEvent = ({
    isValid,
  }: ShortTermTrendPreviewEventProps): Event => {
    const properties: Record<string, any> = {
      insight_name: 'ShortTermTrendPreview',
      is_valid: isValid,
    }

    return new Event({
      properties: properties,
      eventName: 'insight_state',
      type: EventType.Stat,
    })
  }
}
