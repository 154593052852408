// bypassing named imports to avoid jest spyOn issues with the useDeviceConnection hook
// eslint-disable-next-line
import * as hooks from './use-device-connection'
import Chai from 'types/chai'
import { DeviceConnectionContextType } from './DeviceConnectionProvider'
import { INITIAL_STATE } from './reducer'

export const DEFAULT_DEVICE = {
  deviceId: 'e0x5eucLOLf4SxYDsHnIA2',
  hardwareId: '233a7f16051f0200262a06',
  model: 'JUUL2',
  name: 'Virgil',
  serialNumber: 'Q123456',
  version: '2.1.5',
}

export const getFakeContext = (
  params?: Partial<Chai.Context>,
) => ({
  userPreferences: { set: jest.fn() as Chai.Context['userPreferences']['set'] } as Chai.Context['userPreferences'],
  ...params,
} as Chai.Context)

export const getFakeJuulInfo = (CUSTOM_JUUL_INFO = {}) => ({
  batteryHealth: 96,
  batteryLevel: 44,
  hardwareId: DEFAULT_DEVICE.hardwareId,
  hasPod: true,
  isChargerConnected: false,
  isLocked: false,
  model: DEFAULT_DEVICE.model,
  name: DEFAULT_DEVICE.name,
  passportEnabled: false,
  passportLocked: false,
  serialNumber: DEFAULT_DEVICE.serialNumber,
  version: DEFAULT_DEVICE.version,
  ...CUSTOM_JUUL_INFO,
} as Chai.JuulInfo)

const baseFunctions = () => ({
  copy: jest.fn(),
  equals: jest.fn(),
  hashCode: jest.fn(),
})

export const getFakePodInfo = (CUSTOM_POD_INFO = {}) => ({
  fillDate: '2021-04-28T18:49:11Z',
  id: '020000013CD0108188428A26',
  lastPuff: '2022-03-02T19:02:20Z',
  podConsumptionEstimate: {
    estimate: null,
    status: '',
    ...baseFunctions(),
  },
  recipe: {
    color: '#00ab57',
    flavorName: 'Crisp Menthol',
    gradientColors: ['#00ab57', '#00ab57'],
    id: BigInt(131358),
    strength: '18 mg/mL',
    ...baseFunctions(),
  },
  recipeId: BigInt(131358),
  status: {
    canCommunicate: true,
    isAuthentic: true,
    isError: () => false,
    isExpired: false,
    isPodPresent: true,
    isRecalled: false,
    isRefilled: false,
    ...baseFunctions(),
  },
  usage: 52,
  ...baseFunctions(),
  ...CUSTOM_POD_INFO,
})

export const getFakePodStatus = (CUSTOM_POD_STATUS = {}) => ({
  canCommunicate: true,
  isAuthentic: true,
  isError: () => false,
  isExpired: false,
  isPodPresent: true,
  isRecalled: false,
  isRefilled: false,
  ...CUSTOM_POD_STATUS,
} as Chai.PodStatus)

export const getFakeFirmwareUpdateProgress = (CUSTOM_DFU_PROGRESS = {}) => ({
  firmwareWriteProgressPercent: 0,
  status: Chai.dfu.DfuStatus.RUNNING,
  statusDetail: Chai.dfu.DfuStatusDetail.UNZIPPED,
  ...CUSTOM_DFU_PROGRESS,
} as Chai.dfu.DfuProgress)

export const getMockGadgetsHandler = (
  params?: Partial<Chai.gadgets.Gadgets>,
) => ({
  addListener: jest.fn() as Chai.gadgets.Gadgets['addListener'],
  pair: jest.fn() as Chai.gadgets.Gadgets['pair'],
  removeListener: jest.fn() as Chai.gadgets.Gadgets['removeListener'],
  ...params,
} as Chai.gadgets.Gadgets)

export const getMockConnectable = (
  params?: Partial<Chai.gadgets.Connectable>,
) => ({
  __doNotUseOrImplementIt: null,
  connect: jest.fn() as Chai.gadgets.Connectable['connect'],
  disconnect: jest.fn() as Chai.gadgets.Connectable['disconnect'],
  ...params,
} as Chai.gadgets.Connectable)

export const getMockBeepable = (
  params?: Partial<Chai.gadgets.Beepable>,
) => ({
  __doNotUseOrImplementIt: null,
  beep: jest.fn() as Chai.gadgets.Beepable['beep'],
  ...params,
} as Chai.gadgets.Beepable)

export const getMockLockable = (
  params?: Partial<Chai.gadgets.Lockable>,
) => ({
  __doNotUseOrImplementIt: null,
  lock: jest.fn() as Chai.gadgets.Lockable['lock'],
  unlock: jest.fn() as Chai.gadgets.Lockable['unlock'],
  ...params,
} as Chai.gadgets.Lockable)

export const getMockNameable = (
  params?: Partial<Chai.gadgets.Nameable>,
) => ({
  __doNotUseOrImplementIt: null,
  setName: jest.fn() as Chai.gadgets.Nameable['setName'],
  ...params,
} as Chai.gadgets.Nameable)

export const getMockFirmwareUpdatable = (
  params?: Partial<Chai.gadgets.FirmwareUpdatable>,
) => ({
  __doNotUseOrImplementIt: null,
  metaData: {
    changeLog: {
      detail: 'This update contains bug fixes and firmware upgrades.',
      type: '/v1/markdown',
    } as Chai.dfu.Changelog,
    type: 0,
    version: '2.1.5',
  } as Chai.dfu.MetaData,
  performUpdate: jest.fn() as Chai.gadgets.FirmwareUpdatable['performUpdate'],
  priority: 'default',
  status: Chai.dfu.DfuStatus.READY,
  ...params,
} as Chai.gadgets.FirmwareUpdatable)

export const getMockFirmwareSwitchable = (
  params?: Partial<Chai.gadgets.FirmwareSwitchable>,
) => ({
  __doNotUseOrImplementIt: null,
  status: Chai.gadgets.ImageSwapStatusString.READY,
  swapFirmwareImage: jest.fn() as Chai.gadgets.FirmwareSwitchable['swapFirmwareImage'],
  ...params,
} as Chai.gadgets.FirmwareSwitchable)

export const getMockGadget = (
  params?: Partial<Chai.gadgets.Gadget>,
) => ({
  beepable: getMockBeepable(),
  connectable: getMockConnectable(),
  connectionStatus: 'disconnected',
  firmwareSwitchable: getMockFirmwareSwitchable(),
  firmwareUpdatable: getMockFirmwareUpdatable(),
  hardwareId: DEFAULT_DEVICE.hardwareId,
  juulInfo: getFakeJuulInfo(),
  lastConnectedAt: '2023-09-25T19:00:52Z',
  lockable: getMockLockable(),
  nameable: getMockNameable(),
  podInfo: getFakePodInfo(),
  unpair: jest.fn() as Chai.gadgets.Gadget['unpair'],
  ...params,
} as Chai.gadgets.Gadget)

// ensures all the charts return valid for testing
export const getMockUsageInsights = (
  params?: Partial<Chai.UsageInsights>,
) => ({
  attachBatteryConsumptionAdapter: jest.fn((
    _canvas: HTMLCanvasElement,
    onChargesPerDayChanged: (p0: number) => void,
    onEstimationChanged: (p0: Chai.UsageEstimate) => void,
  ) => {
    onChargesPerDayChanged(0)
    onEstimationChanged({} as Chai.UsageEstimate)
  }) as Chai.UsageInsights['attachBatteryConsumptionAdapter'],

  attachLongTermTrend: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: string, p1: number, p2: number, p3: boolean) => void,
  ) => { callback('', 0, 0, true) }) as Chai.UsageInsights['attachLongTermTrend'],

  attachLongTermTrendMicro: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: string, p1: number, p2: boolean) => void,
  ) => { callback('', 0, true) }) as Chai.UsageInsights['attachLongTermTrendMicro'],

  attachPodConsumptionAdapter: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: number, p1: number, p2: number) => void,
  ) => { callback(0, 0, 0) }) as Chai.UsageInsights['attachPodConsumptionAdapter'],

  attachPodHistoryAdapter: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: number, p1: string, p2: string, p3: string, p4: boolean) => void,
  ) => {
    callback(0, '', '', '', true)
    return {
      __doNotUseOrImplementIt: null,
      setMode: jest.fn(),
    } as Chai.PodHistoryInsightUiState
  }) as Chai.UsageInsights['attachPodHistoryAdapter'],

  attachPuffAverageByDayAdapter: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: string, p1: string, p2: boolean, p3: boolean) => void,
  ) => { callback('', '', true, false) }) as Chai.UsageInsights['attachPuffAverageByDayAdapter'],

  attachPuffAverageByHourAdapter: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: number, p1: number, p2: boolean, p3: boolean) => void,
  ) => { callback(0, 0, true, false) }) as Chai.UsageInsights['attachPuffAverageByHourAdapter'],

  attachPuffHistoryAdapter: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: boolean) => void,
  ) => {
    callback(true)
    return {
      __doNotUseOrImplementIt: null,
      setDate: jest.fn(),
      setMode: jest.fn(),
    } as Chai.SummaryInsightUiState
  }) as Chai.UsageInsights['attachPuffHistoryAdapter'],

  attachPuffsTodayAdapter: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: number, p1: boolean, p3: boolean) => void,
  ) => { callback(0, true, true) }) as Chai.UsageInsights['attachPuffsTodayAdapter'],

  attachShortTermTrend: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: string, p1: boolean) => void,
  ) => { callback('', true) }) as Chai.UsageInsights['attachShortTermTrend'],

  attachShortTermTrendGroupedBar: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: boolean) => void,
  ) => { callback(true) }) as Chai.UsageInsights['attachShortTermTrendGroupedBar'],

  attachShortTermTrendMicro: jest.fn((
    _canvas: HTMLCanvasElement,
    callback: (p0: string, p1: boolean) => void,
  ) => { callback('', true) }) as Chai.UsageInsights['attachShortTermTrendMicro'],

  detachAdapter: jest.fn() as Chai.UsageInsights['detachAdapter'],
  removeListener: jest.fn() as Chai.UsageInsights['removeListener'],
  usagePageState: jest.fn() as Chai.UsageInsights['usagePageState'],
  ...params,
} as Chai.UsageInsights)

export const mockDeviceConnectionHook = (
  params?: Partial<DeviceConnectionContextType>,
) => {
  jest.spyOn(hooks, 'useDeviceConnection').mockImplementation(
    () =>
      ({
        ...INITIAL_STATE,
        gadgetsHandler: getMockGadgetsHandler(),
        gadgetsList: [],
        gadgetsObject: {},
        isBluetoothAvailable: jest.fn().mockResolvedValue(true),
        isLogSyncEnabled: false,
        isLogSyncStatusKnown: true,
        setCurrentHardwareId: jest.fn(),
        toggleCloudSyncOptIn: jest.fn().mockResolvedValue({}),
        unlockDevice: jest.fn(),
        ...params,
      } as DeviceConnectionContextType),
  )
}
