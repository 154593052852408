/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** Storage Events in WebApp */
export namespace WebAppStorage {

  export const ClearLocalStorageEvent = (): Event => {
    const properties: Record<string, any> = {
      name: 'ClearLocalStorage',
    }

    return new Event({
      properties: properties,
      eventName: 'storage',
      type: EventType.Stat,
    })
  }

  export const ClearSessionStorageEvent = (): Event => {
    const properties: Record<string, any> = {
      name: 'ClearSessionStorage',
    }

    return new Event({
      properties: properties,
      eventName: 'storage',
      type: EventType.Stat,
    })
  }
}
