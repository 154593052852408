import jwt from 'jsonwebtoken'
/**
   * Generate a mock grant info object
   * @returns A mock grant info object
   * @example
   * ```
   * const grantInfo = generateMockGrantInfo()
   * ```
   *
   *
   **/

export const generateMockGrantInfo = () => {
  const fakeAccessToken = jwt.sign(
    {
      exp: Math.floor(Date.now() / 1000) + 3600,
      guid: 'fake-guid',
      iat: Math.floor(Date.now() / 1000) - 30,
      name: 'John Doe',
      sub: '1234567890',
    },
    'your-256-bit-secret', // Replace with your secret key
    { algorithm: 'HS256' },
  )

  return {
    accessToken: fakeAccessToken,
    createdAt: new Date().toISOString(),
    expiresIn: 3600,
    guid: 'fake-guid',
    keychain: null,
    refreshToken: 'fake-refresh-token',
    scope: 'fake mobile scope',
    tokenType: 'Bearer',
  }
}
