/*
 * AUTOMATICALLY GENERATED FILE - DO NOT EDIT
 */
import { Event } from "../Event"
import { EventType } from "./DataTypes"

/** All Modals in WebApp */
export namespace WebAppModal {

  export enum Name {
    SDKCrashModal = "SDKCrashModal",
    EnableCloudSyncErrorModal = "EnableCloudSyncErrorModal",
    ClearBrowserHistoryModal = "ClearBrowserHistoryModal",
    ClearDeviceDataModal = "ClearDeviceDataModal",
    DisableCloudSyncModal = "DisableCloudSyncModal",
    DisableCloudSyncErrorModal = "DisableCloudSyncErrorModal",
    SwapFirmwareImageModal = "SwapFirmwareImageModal",
    FeedbackModal = "FeedbackModal",
  }

  export const SDKCrashModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "SDKCrashModal",
    })
  }

  export namespace SDKCrashModal {
    export enum ViewClicked {
      ReloadThePage = "ReloadThePage",
      Close = "Close",
    }

    export const ClickReloadThePage = (): Event => {
      const viewClicked = ViewClicked.ReloadThePage
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "SDKCrashModal",
      })
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "SDKCrashModal",
      })
    }
  }

  export const EnableCloudSyncErrorModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "EnableCloudSyncErrorModal",
    })
  }

  export namespace EnableCloudSyncErrorModal {
    export enum ViewClicked {
      Close = "Close",
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "EnableCloudSyncErrorModal",
      })
    }
  }

  export const ClearBrowserHistoryModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ClearBrowserHistoryModal",
    })
  }

  export namespace ClearBrowserHistoryModal {
    export enum ViewClicked {
      SimulateBrowserClearingEvent = "SimulateBrowserClearingEvent",
      Close = "Close",
    }

    export const ClickSimulateBrowserClearingEvent = (): Event => {
      const viewClicked = ViewClicked.SimulateBrowserClearingEvent
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ClearBrowserHistoryModal",
      })
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ClearBrowserHistoryModal",
      })
    }
  }

  export const ClearDeviceDataModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "ClearDeviceDataModal",
    })
  }

  export namespace ClearDeviceDataModal {
    export enum ViewClicked {
      SimulateClearingDataFromJuul2 = "SimulateClearingDataFromJUUL2",
      Close = "Close",
    }

    export const ClickSimulateClearingDataFromJuul2 = (): Event => {
      const viewClicked = ViewClicked.SimulateClearingDataFromJuul2
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ClearDeviceDataModal",
      })
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "ClearDeviceDataModal",
      })
    }
  }

  export const DisableCloudSyncModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "DisableCloudSyncModal",
    })
  }

  export namespace DisableCloudSyncModal {
    export enum ViewClicked {
      TurnOffAutomaticBackups = "TurnOffAutomaticBackups",
      NeverMind = "NeverMind",
      Close = "Close",
    }

    export const ClickTurnOffAutomaticBackups = (): Event => {
      const viewClicked = ViewClicked.TurnOffAutomaticBackups
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "DisableCloudSyncModal",
      })
    }

    export const ClickNeverMind = (): Event => {
      const viewClicked = ViewClicked.NeverMind
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "DisableCloudSyncModal",
      })
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "DisableCloudSyncModal",
      })
    }
  }

  export const DisableCloudSyncErrorModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "DisableCloudSyncErrorModal",
    })
  }

  export namespace DisableCloudSyncErrorModal {
    export enum ViewClicked {
      Close = "Close",
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "DisableCloudSyncErrorModal",
      })
    }
  }

  export const SwapFirmwareImageModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "SwapFirmwareImageModal",
    })
  }

  export namespace SwapFirmwareImageModal {
    export enum ViewClicked {
      Proceed = "Proceed",
      Done = "Done",
      Close = "Close",
    }

    export const ClickProceed = (): Event => {
      const viewClicked = ViewClicked.Proceed
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "SwapFirmwareImageModal",
      })
    }

    export const ClickDone = (): Event => {
      const viewClicked = ViewClicked.Done
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "SwapFirmwareImageModal",
      })
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "SwapFirmwareImageModal",
      })
    }
  }

  export const FeedbackModalEvent = (): Event => {
    const properties: Record<string, any> = {}

    return new Event({
      properties: properties,
      eventName: 'screens',
      type: EventType.Screen,
      screenName: "FeedbackModal",
    })
  }

  export namespace FeedbackModal {
    export enum ViewClicked {
      Submit = "Submit",
      Close = "Close",
    }

    export const ClickSubmit = (): Event => {
      const viewClicked = ViewClicked.Submit
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "FeedbackModal",
      })
    }

    export const ClickClose = (): Event => {
      const viewClicked = ViewClicked.Close
      const properties: Record<string, any> = {
        view_clicked: viewClicked,
      }

      return new Event({
        properties: properties,
        eventName: 'click_event',
        type: EventType.Stat,
        screenName: "FeedbackModal",
      })
    }
  }
}
