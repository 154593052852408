import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { Reoverlay } from 'reoverlay'
import styles from './Modal.module.scss'
import { classNames, variationName } from 'utils/css'
import { ModalWrapper, ModalWrapperProps } from '../ModalWrapper'
import { Controls } from 'components/Controls'
import { Box } from 'components/Box'
import { Button, ButtonProps } from 'components/Button'

export interface ModalProps extends ModalWrapperProps {
  contentFlexDirection?: 'column' | 'row'
  overlay?: ReactNode
  primaryButton?: ButtonProps
  secondaryButton?: ButtonProps
  stackControls?: boolean
  width?: 'base' | 'large'
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  className,
  contentFlexDirection = 'column',
  modalCloseEvent,
  onClose = () => Reoverlay.hideModal(),
  overlay,
  primaryButton,
  secondaryButton,
  stackControls = false,
  width = 'base',
  ...rest
}) => (
  <ModalWrapper
    className={classNames(
      styles.Modal,
      styles[variationName('width', width)],
      className,
    )}
    onClose={onClose}
    modalCloseEvent={modalCloseEvent}
    {...rest}
  >
    <Button
      accessibilityLabel='close modal'
      className={styles.closeButton}
      clickEvent={modalCloseEvent}
      icon='icon/16x16/close'
      onClick={onClose}
      size='small'
    />
    <Box
      className={classNames(
        styles.content,
        styles[variationName('contentFlexDirection', contentFlexDirection)],
        overlay && styles.hide,
      )}
      padding={{
        bottom: 'baseLoose',
        left: 'base',
        right: 'base',
        top: 'loose',
      }}
    >
      {children}
    </Box>
    <Controls
      className={classNames(styles.controls, overlay && styles.hide)}
      padding={{
        bottom: 'baseTight',
        left: 'base',
        right: 'base',
        top: 'baseTight',
      }}
      primaryButton={primaryButton}
      stacked={stackControls}
      secondaryButton={secondaryButton}
    />
    {overlay && (
      <Box className={styles.overlay}>
        {overlay}
      </Box>
    )}
  </ModalWrapper>
)
