import { TextModalProps } from 'modules/ReoverlayModal/components/TextModal'
import { WebAppModal } from 'analytics-events'

export const SDKCrashModal: TextModalProps = {
  description: [{ content: 'Please reload the page.' }],
  heading: { content: 'Sorry, something went wrong.' },
  icon: { name: 'icon/48x48/alert_outlined' },
  modalCloseEvent: WebAppModal.SDKCrashModal.ClickClose(),
  modalScreenEvent: WebAppModal.SDKCrashModalEvent(),
  primaryButton: {
    clickEvent: WebAppModal.SDKCrashModal.ClickReloadThePage(),
    content: 'Reload the page',
  },
}
