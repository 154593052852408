import React, { FC, useState, useMemo } from 'react'
import { Text } from 'components/Text'
import { title, heading, legend } from './localization'
import { LongTermTrend, trendWording } from '../../config'
import { PuffsPerDayLinechart } from '../../charts/PuffsPerDayLinechart'
import { ActivityUpdatePage } from '../../components'
import { WebAppScreens, WebAppInsights, Event } from 'analytics-events'

// long term trends config
const PUFFS_PER_DAY_LINECHART_HEIGHT = 284

export const LongTermTrends: FC = () => {
  // state
  const [isValid, setIsValid] = useState<boolean>(null)
  const [
    { direction, totalWeeks, weeksTrending },
    setTrend,
  ] = useState<LongTermTrend>({
    direction: null,
    totalWeeks: null,
    weeksTrending: null,
  })

  // memoized events
  const screenEvent = useMemo<Event>(() => WebAppScreens.LongTermTrendsEvent(), [])
  const longTermTrendEvent = useMemo<Event>(() => {
    if (isValid === null) return null
    return WebAppInsights.LongTermTrendExpandedEvent({ isValid })
  }, [isValid])

  return (
    <ActivityUpdatePage
      backButton={{ clickEvent: WebAppScreens.LongTermTrends.ClickBack() }}
      isValid={isValid}
      loading={isValid === null}
      pageStateEvent={longTermTrendEvent}
      screenEvent={screenEvent}
    >
      <Text
        color='graphica|base|100'
        margin={{ bottom: 'tight' }}
        message={title}
        tagName='h2'
        typography='subtitleSmall'
        values={{ totalWeeks }}
      />
      <Text
        margin={{ bottom: 'baseLoose' }}
        message={heading}
        tagName='p'
        typography='bodyBigBold'
        values={{ trend: trendWording[direction], weeksTrending }}
      />
      <PuffsPerDayLinechart
        height={PUFFS_PER_DAY_LINECHART_HEIGHT}
        setIsValid={setIsValid}
        setTrend={setTrend}
      />
      <Text
        color='ink|base|50'
        margin={{ top: 'baseLoose' }}
        message={legend}
        tagName='p'
        typography='captionBase'
      />
    </ActivityUpdatePage>
  )
}
