import React, {
  FC,
  createContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react'
import { useRouter } from 'next/router'
import { Event, WebAppStorage, WebAppLogin } from 'analytics-events'
import Chai from 'types/chai'
import { AnalyticsContextType } from './types'
import {
  segmentAnalyticsHook,
  segmentAnalyticsIdentify,
  segmentAnalyticsIdentifyReset,
  segmentAnalyticsPage,
} from './segment'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { bugsnagClient } from 'utils/bugsnag'
import { getAppVersion } from 'utils/app'

export const AnalyticsContext =
  createContext<AnalyticsContextType | null>({
    analytics: null,
    analyticsIdentify: null,
    analyticsIdentifyReset: null,
    reportEvent: null,
    storageEvents: null,
    trackSignIn: null,
  })

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  /** ROUTER */
  const router = useRouter()

  /** CONTEXT */
  const {
    isNewClient,
    sdkContext,
    userClientId,
  } = useConxSdk()

  /** LOCAL STATE */
  const [analytics, setAnalytics] = useState<Chai.Analytics | null>(null)

  /** WATCHERS */
  // Setup the analytics pipeline
  useEffect(() => {
    if (!sdkContext || !userClientId) return

    if (!analytics) {
      const sdkAnalytics = new Chai.Analytics(sdkContext)
      sdkAnalytics.attachConsumer(segmentAnalyticsHook)
      setAnalytics(sdkAnalytics)

      bugsnagClient?.setUser(userClientId)
    }
  }, [analytics, sdkContext, userClientId])

  /**
   * Track client-side page views with Segment
   */
  useEffect(() => {
    // wait until app load to track the first screen
    if (!analytics) return

    segmentAnalyticsPage(router.pathname)
  }, [analytics, router.pathname])

  /**
   * Track app-launch events.
   */
  useEffect(() => {
    if (!analytics) return

    reportEvent(WebAppLogin.LoginAttemptEvent({
      type: isNewClient
        ? WebAppLogin.LaunchType.FirstAttempt
        : WebAppLogin.LaunchType.ReturnUser,
    }))
  }, [analytics, isNewClient])

  /**
   * Identify visitors with Segment.
   */
  useEffect(() => {
    if (!window?.analytics || !userClientId) return

    window.analytics.identify(undefined, {
      release: getAppVersion(),
      userClientId,
    })
  }, [userClientId])

  /** EVENT TRACKERS */

  /**
   * Report an event to segment via the SDK.
   * @param event the event to report
   */
  const reportEvent = (event: Event) => {
    if (!analytics) return
    analytics.report(event)
  }

  /**
   * Track a user sign-in event.
   * @param userGuid the user's GUID
   */
  const trackSignIn = (userGuid: string) => {
    segmentAnalyticsIdentify(userGuid)
    reportEvent(WebAppLogin.LoginSucceededEvent())
  }

  /**
   * Storage events, for local and session storage
   */
  const storageEvents = {
    logLocalStorageCleared: () => reportEvent(WebAppStorage.ClearLocalStorageEvent()),
    logSessionStorageCleared: () => reportEvent(WebAppStorage.ClearSessionStorageEvent()),
  }

  // Setup the device connection context interface
  const values: AnalyticsContextType = {
    analytics,
    analyticsIdentify: segmentAnalyticsIdentify,
    analyticsIdentifyReset: segmentAnalyticsIdentifyReset,
    reportEvent,
    storageEvents,
    trackSignIn,
  }

  // Finally, return the interface that we want to expose to our other components
  return (
    <AnalyticsContext.Provider value={values}>
      {children}
    </AnalyticsContext.Provider>
  )
}
