import React, { FC, PropsWithChildren, useRef, useEffect } from 'react'
import { Reoverlay } from 'reoverlay'
import styles from './ModalWrapper.module.scss'
import { classNames } from 'utils/css'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { Event } from 'analytics-events'

export type ModalWrapperProps = {
  /** Additional classnames for the modal */
  className?: string
  /** Event fired when clicking outside the modal */
  modalCloseEvent?: Event
  /** Event fired when a new modal screen is defined */
  modalScreenEvent?: Event
  /** Function called when clicking outside the modal */
  onClose?(): void
  /** Additional classnames for the modal wrapper */
  wrapperClassName?: string
}

export const ModalWrapper: FC<PropsWithChildren<ModalWrapperProps>> = ({
  children,
  className,
  modalCloseEvent,
  modalScreenEvent,
  onClose = () => Reoverlay.hideModal(),
  wrapperClassName,
}) => {
  // hooks
  const { reportEvent } = useAnalytics()

  // refs
  const wrapperElement = useRef(null)

  // functions
  const handleClickOutside = (e) => {
    if (e.target === wrapperElement.current) {
      onClose()
      modalCloseEvent && reportEvent(modalCloseEvent)
    }
  }

  // effects
  useEffect(() => {
    const { body } = document
    body.classList.add('prevent-scroll')

    return () => {
      body.classList.remove('prevent-scroll')
    }
  }, [])

  useEffect(() => {
    if (!modalScreenEvent) return

    reportEvent(modalScreenEvent)
  }, [modalScreenEvent])

  return (
    <div
      className={classNames(styles.ModalWrapper, wrapperClassName)}
      onClick={handleClickOutside}
      ref={wrapperElement}
      role='presentation'
    >
      <div className={className} role='dialog'>
        {children}
      </div>
    </div>
  )
}
